const external = {
	apps: {
		'external-audits': {
			view: false,
			create: false,
			manage: false,
		},
	},
	reports: {
		audits: { view: false },
	}
};

const tenant = {

	apps: {
		dashboard: {
			view: true
		},
		audits: {
			view: false,
			create: false,
			manage: false,

			do_export: false,

			see_financials: false, // see figures and financials
		},

		estimates: {
			view: false,
			create: false,
			manage: false,

			do_audit: false, // can perform an audit // i don't think we need this
			do_booking: false,
			do_export: false,

			see_financials: false, // see figures and financials
			see_audits: false // see all audits
		},
		assessments: {
			view: false,
			create: false,
			manage: false,

		},
		bookings: {
			view: false,
			create: false,
			manage: false
		},
		heatmap: {
			view: false,
			manage: false
		},
	},

	manage: {
		debtors: {
			view: false,
			manage: false
		},
		departments: {
			view: false,
			manage: false
		},
		events: {
			view: false,
			manage: false
		},
		facilities: {
			view: false,
			manage: false
		},
		paint_codes: {
			view: false,
			manage: false
		},
		users: {
			view: false,
			manage: false
		},
		work_providers: {
			view: false,
			manage: false
		},
	},

	reports: {
		submissions: { view: false },
		failed_exports: { view: false },
	},

}

const client = {

}
const system = {

}


const abilities = {
	system,
	tenant,
	external,
	client
};

Object.freeze(abilities);

export const abilitiesTemplate = abilities;
