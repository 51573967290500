import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';

const externalMenuItems = [
	{
		id: 'dashboard',
		children: [
			{
				title: 'Dashboard',
				type: 'item',
				url: '/',
				icon: DashboardIcon,
				gate: 'tenant.apps.dashboard.view'
			}
		]
	},
	{
		id: 'apps',
		title: 'Apps',
		children: [
			{
				title: 'Estimates',
				type: 'item',
				url: '/estimates',
				icon: DynamicFormIcon,
				gate: 'tenant.apps.estimates.view',
				disabled: true,
				chip: {
					label: 'TBA',
				}
			},
			{
				title: 'Audits',
				type: 'item',
				url: '/external-audits',
				icon: ContentPasteSearchIcon,
				gate: 'external.apps.external-audits.view'
			}
		]
	},
	{
		id: 'reports',
		title: 'Reports',
		children: [
			{
				title: 'Audits',
				type: 'item',
				url: '/audits',
				icon: DynamicFormIcon,
				gate: 'tenant.apps.estimates.view',
				disabled: true,
			}
		]
	}
];

export default externalMenuItems;
