import { Accordion, AccordionSummary, Typography, AccordionDetails, Stack } from "@mui/material";
import Page from "~/components/Page";
import PageTitle from "~/components/PageTitle";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ChangeLogPage = () => {

	return (
		<Page title="Change Log">
			<PageTitle>Change Log</PageTitle>
			{changelogData.map((release, index) => (
				<Accordion key={index} defaultExpanded={[0, 1].includes(index)}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Stack>
							<Typography>
								Release {release.version}
							</Typography>
							<Typography variant="subtitle2">
								{release.date}
							</Typography>
						</Stack>
					</AccordionSummary>
					<AccordionDetails>
						{release.changes.added.length > 0 && (
							<>
								<Typography variant="subtitle1">Added</Typography>
								<ul>
									{release.changes.added.map((item, idx) => (
										<li key={idx}>
											<Typography variant="body2">{item}</Typography>
										</li>
									))}
								</ul>
							</>
						)}
						{release.changes.changed.length > 0 && (
							<>
								<Typography variant="subtitle1">Changed</Typography>
								<ul>
									{release.changes.changed.map((item, idx) => (
										<li key={idx}>
											<Typography variant="body2">{item}</Typography>
										</li>
									))}
								</ul>
							</>
						)}
						{release.changes.fixed.length > 0 && (
							<>
								<Typography variant="subtitle1">Fixed</Typography>
								<ul>
									{release.changes.fixed.map((item, idx) => (
										<li key={idx}>
											<Typography variant="body2">{item}</Typography>
										</li>
									))}
								</ul>
							</>
						)}
					</AccordionDetails>
				</Accordion>
			))}

		</Page>
	)
};

const changelogData = [
	{
		version: '1.8.0',
		date: '2025-01-29',
		changes: {
			added: [
				'Booking date will sync to IBS',
				'Facility and booking sms now supports a map link',
				'Auto parts pricing will now include the facilty address in the comments',
				'Setup workproviders environment',
				'Added inital external audits'
			],
			changed: [
				'Various R&R labels for conventional repairs',
				'Added mobile to user details',
				'Improved logic that finds matching IBS quote to be more througher',
				'Tweaked office manager permissions to allow for user management',
				'Qtr panles can no longer be renewed',
				'Updated IBS sync logic to better handle errors'
			],
			fixed: [
				'Auto-parts pricing misleading failure message when there is simply no parts to price',
			]
		}
	},
	{
		version: '1.7.1',
		date: '2024-11-06',
		changes: {
			added: [
				'Owner details now will sync to IBS',
			],
			changed: [
				'Correctly sync MISC line items for private work',
			],
			fixed: [
				'Upon a failed sync, the system wont try again which was casuing duplicated line items',
			]
		}
	},
	{
		version: '1.7.0',
		date: '2024-11-01',
		changes: {
			added: [
				'Added workprovider CRUD',
				'Added Debtor CRUD',
				'Added audit comparison export button to audit comparision page',
				'Added Help Center',
				'Updated Turret R&R and R&I to be itemised in exported estimates',
				'Facilities have a specific TBA flag for address',
				'Support incident details for assessments'
			],
			changed: [
				'Updated exported line items to itemise R&R and R&I for replacement Turret',
				'Updated permissions to support Office Manager role with appropiate permissions',
				'Modified technician permissions to allow support workproviders and paint codes',
				'Download button on estimates page and audits will now consistently return the estimate export PDF',
				'Changed events to no longer require a workprovider',
				'Updated Turret pricing to be more accurate',
				'Improve filter bar responsiveness',
				'Improve performance of authentication',
				'Updated security headers to meet latest standards',
				'Allowed dissmissing of notifications',
			],
			fixed: [
				'Duplicate R&R from blends where fittings of the same panel were included',
				'Duplicate paint labour from blends',
			]
		}
	},
	{
		version: '1.6.0',
		date: '2024-09-23',
		changes: {
			added: [
				'Added Manage Facility',
				'Added Manage Events',
				'Auto price parts from IBS',
				'Updated suburbs list and setup autocomplete for suburb field',
			],
			changed: [

			],
			fixed: [

			]
		}
	},
	{
		version: '1.5.2',
		date: '2024-09-05',
		changes: {
			added: [
				'Look up R&R and MISC prices during export if price doesn\'t exist as a safety net',
				'Synced Labour lines are now market as PDR in IBS along with the dent size',
			],
			changed: [
				'Upgraded activity log to better track exporting and syncing activites',
				'Renamed some line item descriptions to correctly triggure workshop activities in IBS'
			],
			fixed: [
			]
		}
	},
	{
		version: '1.5.1',
		date: '2024-08-26',
		changes: {
			added: [
				'Automatically assign event to assessments based on location proximity',
			],
			changed: [
			],
			fixed: [
			]
		}
	},
	{
		version: '1.5.0',
		date: '2024-08-19',
		changes: {
			added: [
				'Added Rego state to job details screen.',
				'Impliment photos sync to IBS.',
				'Added camera support for photo upload.',
				'Ability to create/manage assessments from the app',
				'Introduce prototype for guessing parts pricing from historical data',
				'Parts will automatically be sent off to be priced after syncing with IBS',
				'Ability to unlink assessment from estimate',
			],
			changed: [
				'Updated UI for IBS manual assingment when auto-sync failed to match the new auto-sync process.',
				'IBS sync will auto VIN lookup in IBS',
			],
			fixed: [
				'Increase photos count upload limit.',
				'MISC UI and data fluidity issues.'
			]
		}
	},
	{
		version: '1.4.0',
		date: '2024-07-15',
		changes: {
			added: [
				'Introduce panel blending for paint and R&R',
			],
			changed: [
				'Force R&R custom items to have a price.',
				'Improved labels and UI to understand status and requires action for audits'
			],
			fixed: [
				'IBS sync R&R/Sublet items categorisation.',
				'MISC UI and data fluidity issues.'
			]
		}
	},
	{
		version: '1.3.0',
		date: '2024-07-02',
		changes: {
			added: [
				'Introduce file upload support',
				'Photo buckets added to Estimates and Audits',
				'Added change log page.',
				'Added department management.',
				'Enabled estimate automatic sync.'
			],
			changed: [
				'Mobile optimisations for various screens and components paying attention to zoom and font sizes.',
			],
			fixed: [
				'Audit correctly handles sublets differences.',
				'Correct deployment URL prefix to handle loading when not on root path.'
			]
		}
	},
	{
		version: '1.2.0',
		date: '2024-05-24',
		changes: {
			added: [
				'Added autit feature for estimates.',
				'Introduced user managagement along with roles and permissions for better security.',
			],
			changed: [
				'Updated the primary and secondary color themes for a fresher look.',
			],
			fixed: [
				'Various Bug Fixes.'
			]
		}
	},
	{
		version: '1.1.0',
		date: '2024-05-08',
		changes: {
			added: [
				'Setup generic web application layout with sidebar and header components to suite a Portal application with multiple app & features.',
			],
			changed: [
				'Redesigned app layout to support typical portal features like user profile, settings, and notifications.',
			],
			fixed: []
		}
	},
	{
		version: '1.0.0',
		date: '2024-03-16',
		changes: {
			added: [
				'Launched the app with the following core features:',
				'Home page with lastest estimates',
				'User authentication (login).',
				'Estimate capture form.',
			],
			changed: [],
			fixed: []
		}
	}
];

export default ChangeLogPage