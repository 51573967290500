import { Box, Stack, Typography } from "@mui/material";
import { toCurrencyDifferency, toPercentage } from "~/helpers/stringHelpers";

const SubmissionSummaryExternalAudit = ({
	audit
}) => {

	if (!audit) return null;

	return (
		<Box sx={{
			borderRadius: 1,
			borderStyle: 'solid',
			borderWidth: 1,
			px: 2,
			py: 0.2,
			width: '100%',
		}}>
			<Stack direction='row' spacing={2} justifyContent="space-between" alignItems="center">
				<Typography>{toCurrencyDifferency(audit.difference_total, 0)}</Typography>

				<Typography>{toPercentage(audit.difference_percentage)}</Typography>
			</Stack>
		</Box>
	);
}

export default SubmissionSummaryExternalAudit;